<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getLocationPartyOverview } from "@/services/api/location.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import _ from "lodash";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  filterData: {
    type: Array,
    default: function () {
      return [];
    },
  },
  locationReference: {
    type: String,
    default: undefined,
  },
  allowMutation: {
    type: Boolean,
    default: false,
  },
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Partij naam", value: "partyName" },
  { text: "Adres", value: "addressDescription" },
  { text: "Rol", value: "roleDescription" },
  { text: "Installatie groep", value: "installationGroupReference" },
  { text: "Bouwkundige groep", value: "buildingGroupDescription" },
  { text: "Maatregelen groep", value: "ecmGroupReference" },
  { text: "Begindatum", value: "dateFrom" },
  { text: "Einddatum", value: "dateTill" },
  { text: "Versies", value: "detailCnt", sortable: false, align: "center" },
];
const headers = computed(() => {
  return _.filter(_headers, (item) => {
    return !("disallowMutation" in item) || item.disallowMutation === props.allowMutation;
  });
});

watch(
  () => [props.filterData, props.locationReference],
  () => {
    fetchFirstPage();
  },
  { immediate: true, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("%cPartyAddressOverviewGrid, getData", "background: #e3fbe3; color: black", pageSize, props.locationReference, props.filterData);
  const pageNumber0 = pageNumber - 1;
  const response = await getLocationPartyOverview(props.locationReference, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  console.log("PartyAddressOverviewGrid, getData", response);
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.dateFrom`]="{ item }">
        {{ formatDateUtc(item.dateFrom) }}
      </template>
      <template v-slot:[`item.dateTill`]="{ item }">
        {{ formatDateUtc(item.dateTill) }}
      </template>
      <template v-slot:[`item.addressDescription`]="{ item }">
        <span v-if="item.addressDescription == 'not valid'">niet geldig</span>
        <span v-else>{{ item.addressDescription }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'PartyAddressDetails', params: { id: item.id.toString(), addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-if="allowMutation" v-slot:[`item.partyName`]="{ item }">
        <v-btn :to="{ name: 'Party', params: { partyReference: item.partyReference.toString(), id: item.reference, addModus: false } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
        {{ item.partyName }}
      </template>
    </v-data-table>
  </div>
</template>
