<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getLocationRegistersOverview } from "@/services/api/location.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 0;

const props = defineProps({
  locationReference: String,
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Omschrijving", value: "register.description" },
  { text: "Type", value: "register.typeDescription" },
  { text: "Energietype", value: "register.energyTypeDescription" },
  { text: "Eenheid", value: "register.unitTypeDescription" },
  { text: "Factor", value: "allocFact", sortable: false, align: "center" },
  { text: "PPS-factor", value: "ppsFactor", sortable: false, align: "center" },
  { text: "Bron", value: "register.source" },
  { text: "Bronreferentie", value: "register.sourceReference" },
  { text: "Begindatum", value: "dateFrom" },
  { text: "Einddatum", value: "dateTill" },
  { text: "Versies", value: "detailCnt", sortable: false, align: "center" },
];
const headers = computed(() => _headers);

watch(
  () => props.locationReference,
  () => {
    //console.log("LocationRegisterOverviewGrid, watch locationReference");
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getLocationRegistersOverview(props.locationReference, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  //console.log("%cLocationRegisterOverviewGrid, getData", "background: GoldenRod; color: DarkOrchid", response);

  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h4>Meter koppeling</h4>
        </header>
      </v-col>
      <v-col cols="1">
        <v-btn dark class="primary" :to="{ name: 'LocationNewRegisterDetailsAdd', params: { locationReference: locationReference.toString(), addModus: true } }">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'LocationRegisterDetails', params: { locationReference: locationReference.toString(), id: item.id, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.register.description`]="{ item }">
        {{ item.register.description }}
        <v-btn :to="{ name: 'EU-Register', params: { registerId: item.register.id.toString(), addModus: false } }" icon small plain>
          <v-icon small> mdi-link-variant </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.register.isInd`]="{ item }">
        <v-layout wra justify-center>
          <v-checkbox class="ma-0 pa-0" disabled v-model="item.register.isInd" hide-details></v-checkbox>
        </v-layout>
      </template>
      <template v-slot:[`item.dateFrom`]="{ item }">
        {{ formatDateUtc(item.dateFrom) }}
      </template>
      <template v-slot:[`item.dateTill`]="{ item }">
        {{ formatDateUtc(item.dateTill) }}
      </template>
    </v-data-table>
  </div>
</template>
