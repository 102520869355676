<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getLocationDetailByReference } from "@/services/api/location.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import router from "@/router/index";

import LocationParties from "@/components/location/LocationParties.vue";
import LocationAddress from "@/components/location/LocationAddressGrid.vue";
import LocationConstruction from "@/components/location/LocationConstructionGrid.vue";
import LocationInstallation from "@/components/location/LocationInstallationGrid.vue";
import LocationRegister from "@/components/location/LocationRegisterOverviewGrid.vue";
import EnergyLabel from "@/components/location/EnergyLabelOverviewGrid.vue";

import LocationDiagram from "@/components/location/LocationDiagram.vue";

const { formatDateUtcD, formatDateUtcDT } = useDateTimeFormatter();

const props = defineProps({
  locationReference: String,
});

const location = ref({
  id: undefined,
  reference: undefined,
  note: "",
  descr: "",
  typDescr: "",
  consDat: undefined,
  renovDat: undefined,
  eedTypDescr: "",
  gbsTypDescr: "",
  ecmFuncName: "",
  nta8800FuncDescr: "",
  weiiBldCatName: "",
  weiiBldCatDecreeName: "",
  usgStat: "",
  bvo: 0,
  nvo: 0,
  vvo: 0,
  go: 0,
  valFrom: undefined,
  valTill: undefined,
  dateFrom: undefined,
  dateTill: undefined,
  createdBy: "",
  createdDate: undefined,
  modBy: "",
  modDescr: "",
});
const diagramLarge = ref(false);

const addAddressLink = computed(() => {
  return { name: "AddressDetailsAdd", params: { locationReference: props.locationReference.toString(), addModus: true } };
});
const addSublocationLink = computed(() => {
  return { name: "SublocationAdd", params: { locationReference: props.locationReference.toString(), addModus: true } };
});

watch(
  () => props.locationReference,
  () => {
    console.log("*********");
    console.log("%cLocation watch locationReference ", "background: #B8E6FA; color: black", props.locationReference);
    fetchData();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  const response = await getLocationDetailByReference(props.locationReference);
  console.log("Location fetchData ", props.locationReference, response);

  if (response && !response.error) {
    location.value = response.data;
    location.value.note = response.data.note ? response.data.note : "-";
    location.value.modDescr = response.data.modDescr ? response.data.modDescr : "-";
    location.value.typDescr = response.data.typDescr ? response.data.typDescr : "-";
    location.value.eedTypDescr = response.data.eedTypDescr ? response.data.eedTypDescr : "-";
    location.value.gbsTypDescr = response.data.gbsTyp ? response.data.gbsTyp : "-";
    location.value.ecmFuncName = response.data.ecmFuncName ? response.data.ecmFuncName : "-";
    location.value.nta8800FuncDescr = response.data.nta8800FuncDescr ? response.data.nta8800FuncDescr : "-";
    location.value.weiiBldCatName = response.data.weiiBldCatName ? response.data.weiiBldCatName : "-";
    location.value.weiiBldDecreeName = response.data.weiiBldDecreeName ? response.data.weiiBldDecreeName : "-";
    location.value.usgStat = response.data.usgStat ? response.data.usgStat : "-";
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}

function handleNavigateDiagram(value) {
  console.log("Location handleNavigateDiagram ", value);
  switch (value.code) {
    case "L":
      router.push({
        name: "Location",
        params: { locationReference: value.reference },
      });
      break;
    case "P":
      router.push({
        name: "Party",
        params: { partyReference: value.reference },
      });
      break;
  }
}
</script>

<template>
  <v-container fluid pa-0 pb-6>
    <header class="app-welcome-message">
      <h2>
        <v-btn v-if="locationReference" :to="{ name: 'LocationDetails', params: { locationReference: locationReference } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        Overzicht
      </h2>
    </header>
    <v-row>
      <v-col>
        <v-container class="boxed" fluid :class="{ noBorders: false }" v-if="diagramLarge">
          <header class="d-flex justify-space-between">
            Diagram
            <button @click="diagramLarge = !diagramLarge" class="d-none d-lg-flex">
              <v-icon>mdi-arrow-collapse</v-icon>
            </button>
          </header>
          <v-row
            ><v-col> <location-diagram :locationReference="locationReference" @navigate="handleNavigateDiagram"></location-diagram> </v-col
          ></v-row>
        </v-container>
        <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
          <!-- <masonry :cols="{ default: 3, 768: 2, 1264: 1 }" :gutter="12" class="boxed"> -->
          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Algemene gegevens</header>
            <v-row>
              <v-col cols="4"><label>INNAX-ID</label></v-col>
              <v-col>
                {{ location.reference }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Omschrijving</label></v-col>
              <v-col>
                {{ location.descr }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4"><label>Bouwdatum</label></v-col>
              <v-col>
                {{ formatDateUtcD(location.consDat) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Renovatiedatum</label></v-col>
              <v-col>
                {{ formatDateUtcD(location.renovDat) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Gebruikstatus</label></v-col>
              <v-col>
                {{ location.usgStat }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Is dit gebouw een monument?</label></v-col>
              <v-col>
                {{ location.isMonumental === true ? "Ja" : "Nee" }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Geldig van</label></v-col>
              <v-col> {{ formatDateUtcD(location.valFrom) }}</v-col>
              <v-col> <label>Geldig tot</label></v-col>
              <v-col> {{ formatDateUtcD(location.valTill) }}</v-col>
            </v-row>
            <v-row v-if="location.detailCnt > 1">
              <v-col cols="4"><label>Versies</label></v-col>
              <v-col> {{ location.detailCnt }}</v-col>
            </v-row>
            <v-row v-if="location.detailCnt > 1">
              <v-col cols="4"><label>Begindatum</label></v-col>
              <v-col> {{ formatDateUtcD(location.dateFrom) }}</v-col>
              <v-col> <label>Einddatum</label></v-col>
              <v-col> {{ formatDateUtcD(location.dateTill) }}</v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" fluid :class="{ noBorders: false }" v-if="!diagramLarge">
            <header class="d-flex justify-space-between">
              Diagram
              <button @click="diagramLarge = !diagramLarge" class="d-none d-lg-flex">
                <v-icon>mdi-arrow-expand</v-icon>
              </button>
            </header>
            <v-row
              ><v-col> <location-diagram :locationReference="locationReference" @navigate="handleNavigateDiagram"></location-diagram> </v-col
            ></v-row>
          </v-container>
          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Functies</header>
            <v-row>
              <v-col cols="4"><label>Type</label></v-col>
              <v-col>
                {{ location.typDescr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>Functie</label></v-col>
              <v-col>
                {{ location.ecmFuncName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>WEii Bouwbesluit</label></v-col>
              <v-col>
                {{ location.weiiBldDecreeName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>WEii Klasse</label></v-col>
              <v-col>
                {{ location.weiiBldCatName }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>NTA8800 Functie</label></v-col>
              <v-col>
                {{ location.nta8800FuncDescr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>EED</label></v-col>
              <v-col>
                {{ location.eedTypDescr }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label>GBS</label></v-col>
              <v-col>
                {{ location.gbsTypDescr }}
              </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Oppervlakten</header>
            <v-row>
              <v-col cols="8" md="4"><label>Gebruiksoppervlakte (GO)</label></v-col>
              <v-col cols="4" md="2">
                <span v-if="location.go != null">{{ location.go | number("0,0", { thousandsSeparator: "." }) }} m²</span>
                <span v-else>-</span>
              </v-col>
              <v-col cols="8" md="4"><label>Netto vloeroppervlakte (NVO)</label></v-col>
              <v-col cols="4" md="2">
                <span v-if="location.nvo != null">{{ location.nvo | number("0,0", { thousandsSeparator: "." }) }} m²</span>
                <span v-else>-</span>
              </v-col>
              <v-col cols="8" md="4"><label>Bruto vloeroppervlakte (BVO)</label></v-col>
              <v-col cols="4" md="2">
                <span v-if="location.bvo != null">{{ location.bvo | number("0,0", { thousandsSeparator: "." }) }} m²</span>
                <span v-else>-</span>
              </v-col>
              <v-col cols="8" md="4"><label>Verhuurbaar vloeroppervlakte (VVO)</label></v-col>
              <v-col cols="4" md="2">
                <span v-if="location.vvo != null">{{ location.vvo | number("0,0", { thousandsSeparator: "." }) }} m²</span>
                <span v-else>-</span>
              </v-col>
              <v-col cols="8" md="4"><label>Dakoppervlakte (DO)</label></v-col>
              <v-col cols="4" md="2">
                <span v-if="location.do != null">{{ location.do | number("0,0", { thousandsSeparator: "." }) }} m²</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Hernieuwbare energie</header>
            <v-row>
              <v-col cols="4"><label>Gebruik van hernieuwbare energie?</label></v-col>
              <v-col>
                {{ location.isRenewEnergyUsed === true ? "Ja" : "Nee" }}
              </v-col>
              <v-col cols="4"><label>Aandeel elektriciteit</label></v-col>
              <v-col> {{ location.shareOfRenewElecPerc }} % </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label></label></v-col>
              <v-col></v-col>
              <v-col cols="4"><label>Aandeel energie</label></v-col>
              <v-col> {{ location.shareOfRenewEnergyPerc }} % </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"><label></label></v-col>
              <v-col></v-col>
              <v-col cols="4"><label>Aandeel warmte</label></v-col>
              <v-col> {{ location.shareOfRenewHeatPerc }} % </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Notities</header>
            <v-row>
              <v-col> {{ location.note }} </v-col>
            </v-row>
          </v-container>

          <v-container class="boxed" fluid :class="{ noBorders: false }">
            <header>Aanpassing</header>
            <v-row>
              <v-col cols="4" md="3"><label>Aangemaakt op</label></v-col>
              <v-col cols="8" md="3"> {{ formatDateUtcDT(location.createdDate) }} </v-col>
              <v-col cols="4" md="1"><label>door</label></v-col>
              <v-col cols="8" md="3"> {{ location.createdBy }} </v-col>
              <v-col cols="4" md="3"><label>Aangepast op</label></v-col>
              <v-col cols="8" md="3"> {{ formatDateUtcDT(location.modDat) }} </v-col>
              <v-col cols="4" md="1"><label>door</label></v-col>
              <v-col cols="8" md="3"> {{ location.modBy }} </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" md="3"><label>Omschrijving</label></v-col>
              <v-col> {{ location.modDescr }} </v-col>
            </v-row>
          </v-container>
        </masonry>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <location-address class="mt-3" :allowMutation="true" :locationReference="locationReference" :addLink="addAddressLink">
      <template v-slot:header>
        <h4>Adressen</h4>
      </template>
    </location-address>
    <location-address class="mt-3" :allowMutation="true" :locationReference="locationReference" showChilds :addLink="addSublocationLink">
      <template v-slot:header>
        <h4>Sublocatie adressen</h4>
      </template>
    </location-address>
    <location-construction :locationReference="locationReference"></location-construction>
    <location-installation :locationReference="locationReference"></location-installation>
    <location-register :locationReference="locationReference"></location-register>
    <energy-label :locationReference="locationReference"></energy-label>
    <location-parties :allowMutation="true" :locationReference="locationReference"></location-parties>
  </v-container>
</template>
