<script lang="ts" setup>
import { computed, watch, getCurrentInstance, } from "vue";
import { getEnergyLabelsOverview } from "@/services/api/location.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  locationReference: String,
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Label", value: "energyLabel" },
  { text: "Index", value: "energyIndex" },
  { text: "EP 1: Energiebehoefte kWh/m²", value: "energyDemand" },
  { text: "EP 2: Primair fossiel energieverbruik kWh/m²", value: "primaryFossilEnergy" },
  { text: "EP 2 EMG forf.: Primair fossiel energieverbruik kWh/m²", value: "primaryFossilEnergyForfaitair" },
  { text: "EP 3: Aandeel hernieuwbare energie kWh/m²", value: "shareOfRenewableEnergy" },
  { text: "Bron", value: "elSrcDescr" },
  { text: "Opnamedatum", value: "recordedDate" },
  { text: "Registratiedatum", value: "registrationDate" },
];

const headers = computed(() => _headers);

watch(
  () => props.locationReference,
  () => {
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  //console.log("%cEnergyLabelOverviewGrid, getData", "background: GoldenRod; color: DarkOrchid");
  const pageNumber0 = pageNumber - 1;
  const response = await getEnergyLabelsOverview(props.locationReference, pageNumber0, pageSize, sortBy, sortDesc);

  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}


</script>


<template>
  <div>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h4>Labels</h4>
        </header>
      </v-col>
      <v-col cols="1">
        <v-btn dark class="primary" :to="{ name: 'EnergyLabelAdd', params: { locationReference: locationReference.toString(), addModus: true } }">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'EnergyLabelDetail', params: { locationReference: locationReference.toString(), id: item.id, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.recordedDate`]="{ item }">
        {{ formatDateUtc(item.recordedDate) }}
      </template>
      <template v-slot:[`item.registrationDate`]="{ item }">
        {{ formatDateUtc(item.registrationDate) }}
      </template>
    </v-data-table>

  </div>
</template>
