<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getInstallationsOverview } from "@/services/api/installation.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 0;

const props = defineProps({
  locationReference: String,
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Referentie", value: "ref" },
  { text: "Merk", value: "brand" },
  { text: "Model", value: "model" },
  { text: "Type", value: "installationTypeDescription" },
  { text: "Product Type", value: "productTypeDescription" },
  { text: "Control Type", value: "controlTypeName" },
  { text: "Groep", value: "groupRef" },
  { text: "Begindatum", value: "datFrom" },
  { text: "Einddatum", value: "datTill" },
  { text: "Versies", value: "detailCnt", sortable: false, align: "center" },
];
const headers = computed(() => _headers);

watch(
  () => props.locationReference,
  () => {
    //console.log("LocationInstallationGrid, watch locationReference");
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getInstallationsOverview(props.locationReference, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  console.log("%cLocationInstallationGrid, getData", "background: GoldenRod; color: DarkOrchid", response);
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-wrapper">
      <v-col cols="2">
        <header class="app-welcome-message">
          <h4>Installatie gegevens</h4>
        </header>
      </v-col>
      <v-col cols="1">
        <v-btn dark class="primary" :to="{ name: 'InstallationDetailsAdd', params: { locationReference: locationReference.toString(), addModus: true } }">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.datFrom`]="{ item }">
        {{ formatDateUtc(item.datFrom) }}
      </template>
      <template v-slot:[`item.datTill`]="{ item }">
        {{ formatDateUtc(item.datTill) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'InstallationDetails', params: { locationReference: locationReference.toString(), id: item.id, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
