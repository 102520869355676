<script lang="ts" setup>
import { ref, watch } from "vue";
import VueMermaidString from "vue-mermaid-string";
import { getLocationDiagram } from "@/services/api/location.api";

import tinycolor from "tinycolor2";
//import endent from "endent";

const emits = defineEmits(["navigate"]);

const colorDefs = [
  { className: "fcLocation", color: "#B7DDE8", darkenStroke: 50, saturateStroke: 10 }, //blauwig
  { className: "fcLocationParent", color: "#CABAAB", darkenStroke: 50, saturateStroke: 10 }, //grijzig
  { className: "fcLocationChild", color: "#e6ccb2", darkenStroke: 50, saturateStroke: 10 }, //grijzig
  { className: "fcParty", color: "#E5B9B5", darkenStroke: 50, saturateStroke: 10 }, //rodig
  { className: "fcLocationChildParent", color: "#D4D4D4", darkenStroke: 50, saturateStroke: 10 }, //lichtgrijzig
  { className: "fcLocationOther", color: "#eeede7", darkenStroke: 50, saturateStroke: 10 }, //ivory
];

const style = (colorDef) => {
  const fill = colorDef.color;
  const stroke = tinycolor(colorDef.color).darken(colorDef.darkenStroke).saturate(colorDef.saturateStroke).toString();
  return `classDef ${colorDef.className} fill: ${fill}, stroke: ${stroke}`;
};

const props = defineProps({
  locationReference: String,
});

const flowchart = ref(undefined);

watch(
  () => props.locationReference,
  () => {
    console.log("LocationDiagram watch locationReference ", props.locationReference);
    fetchData();
  },
  { immediate: true }
);

function nodeClick(nodeId) {
  const m = nodeId.match(/(\D+)(\d+)/);
  if (m) {
    //console.log(nodeId, nodeId, m[1], m[2]);
    emits("navigate", { code: m[1], reference: m[2] });
  }
}

async function fetchData() {
  console.log("%cLocationDiagram fetchData", "background: pink; color: black", props.locationReference);
  const response = await getLocationDiagram(props.locationReference);
  let data = response.data;
  colorDefs.forEach((colorDef) => {
    data += `${style(colorDef)}\n`;
  });
  //console.log("LocationDiagram flowchart", data);
  flowchart.value = data;
}
</script>

<template>
  <v-layout>
    <vue-mermaid-string v-if="flowchart" :value="flowchart" @node-click="nodeClick"></vue-mermaid-string>
  </v-layout>
</template>
